import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'How we heal autoimmune conditions.',
  items: [
    {
      id: 'hormonal-screening',
      heading: 'We analyze your whole-body health.',
      description:
        'Our world-class clinicians get to know you, in person or virtually, to gain a deeper understanding of your symptoms and what triggers them.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'whole-body-approach',
      heading: 'Advanced tests for deeper insights.',
      description:
        'We prescribe tests to check for autoantibodies, which are proteins that your immune system makes when it’s attacking your own body. We also run tests to determine your gut health, and check for biomarkers for inflammation, signs that autoimmune disease is active.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'personalized-ongoing-support',
      heading: 'Support to make the changes you need.',
      description:
        'Our health coaches help you make the lifestyle changes your clinician may recommend, like dietary changes to identify food triggers and deficiencies, and stress management to keep flares under control.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
