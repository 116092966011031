import React from 'react';
import { Button, HalfAndHalf, Text, TextLockup } from '..';
import { baseSanityNode } from '../content/base-sanity-node';
import { ImageProps, TextLockupProps } from '../types';

const TextLockupData: TextLockupProps = {
  eyebrow: 'How to balance hormones',
  headline: 'Stress-free online holistic care.',
  description:
    'You can meet with your Parsley clinician and health coach via video call, while the My Parsley Health portal keeps you connected to your care team. Easily ask questions, schedule appointments, and manage prescriptions from anywhere.',
  alignment: ['center', 'left'],
  cta: {
    label: 'Meet Our Clinicians',
    destination: '/providers/'
  },
  ctaTrackingLabel:
    'Meet Our Clinicians button on the Stress-free online holistic care module'
};

interface TextLockupContentProps {
  eyebrow?: string;
  image?: ImageProps;
  headline?: string;
  description?: string;
  cta?: {
    destination: string;
    label: string;
  };
  ctaTrackingLabel: string;
}

const TextLockupContent: React.FC<TextLockupContentProps> = ({
  eyebrow = TextLockupData.eyebrow,
  headline = TextLockupData.headline,
  description = TextLockupData.description,
  cta = {
    ...TextLockupData.cta
  }
}) => (
  <TextLockup alignment={TextLockupData.alignment}>
    {eyebrow && (
      <Text variant="p3b" className="mb-xxs" color="dark-green">
        {eyebrow}
      </Text>
    )}
    <Text variant="h2a" className="mb-sm" color="dark-green">
      {headline}
    </Text>
    <Text variant="p2a" className="mb-xs" color="dark-green">
      {description}
    </Text>
    <div className="text-lockup-buttons-container">
      {cta && (
        <Button
          destination={cta.destination}
          variant="filled"
          color="sage"
          trackingLabel={cta.ctaTrackingLabel}
        >
          {cta.label}
        </Button>
      )}
    </div>
  </TextLockup>
);

export const CliniciansHalfAndHalf: React.FC<TextLockupContentProps> = ({
  image = {
    ...baseSanityNode,
    asset: {
      ...baseSanityNode,
      url:
        'https://cdn.sanity.io/images/mur2ep80/production/8d69b3ffc2f5620b8e40f4e854874eb533ba6df7-2400x3000.png'
    },
    alt: 'woman_on_computer_smiling'
  },
  ...props
}) => {
  const data = {
    image: {
      ...image,
      blob: true
    }
  };
  return (
    <HalfAndHalf {...data}>
      <TextLockupContent {...props} />
    </HalfAndHalf>
  );
};
